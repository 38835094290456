<template lang="pug">
.help-topic
  loading(v-if="$fetchState.pending")

  article.help-topic__layout(v-else-if="record")
    .help-topic__header
      h1.help-topic__title(v-text="record.title")
      .help-topic__summary(v-text="record.summary")

    .help-topic__body
      .help-topic__content(
        ref="content"
        v-html="content")

      .help-topic__contact
        h4 Still have questions or feedback?
        a.el-button(
          href="mailto:brands@podfoods.co"
          title="Get in touch"
          target="_blank")
          i.bx.bx-conversation
          span Get in touch with a Pod Foods rep

    aside.help-topic__sidebar
      template(v-if="headings.length")
        .help-topic__section-title ON THIS PAGE
        a.help-topic__heading(
          v-for="i, k in headings"
          :key="k"
          :class="{ [`help-topic__heading--${i.tag}`]: true, 'help-topic__heading--active': '#' + i.slug === hash }"
          :href="`#${i.slug}`"
          @click.prevent="scrollTo(`#${i.slug}`)"
          v-html="i.html")
</template>

<script>
import HeaderBar from '~/components/menus/HelpCenterHeaderBar'
import { minBy } from 'lodash'

export default {
  components: {
    HeaderBar
  },

  scrollToTop: true,

  data () {
    return {
      record: null,
      hash: this.$route.hash
    }
  },

  fetch () {
    return this.$contentful.client
      .getEntries({
        content_type: 'topic',
        limit: 1,
        'fields.slug': this.slug
      })
      .then(res => {
        this.record = res.items
          .map(i => i?.fields || {})
          ?.[0]
      })
  },

  head () {
    return this.$seo.head(
      this.record
        ? {
          title: `${this.record.title} - Help Center`,
          description: this.record.summary
        }
        : {
          title: 'Help Center',
          description: 'How can we help you?'
        }
    )
  },

  computed: {
    slug () {
      return this.$route.params.slug
    },

    headings () {
      if (!this.record) return []
      return this.$contentful.headings(this.record?.content)
    },

    content () {
      return this.$contentful.html(this.record?.content)
    }
  },

  watch: {
    hash (val) {
      history.replaceState({}, '', val)
    }
  },

  mounted () {
    this.hash = this.$route.hash
    window.addEventListener('scroll', this.scroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    scroll (e) {
      const top = window.scrollY + 96
      const all = this.$refs.content
        ?.querySelectorAll(
          ['h1', 'h2', 'h3']
            .map(tag => `.embedded-anchor[id][data-tag=${tag}]`)
            .join(',')
        )
      const anchors = (all ? [...all] : [])
        .map(el => {
          const heading = el.querySelector('h1,h2,h3')
          return {
            element: el,
            heading,
            hash: `#${el.getAttribute('id')}`,
            offset: Math.min(
              Math.abs(heading.offsetTop - top),
              Math.abs(heading.offsetTop + heading.offsetHeight - top)
            )
          }
        })
      const focusing = minBy(anchors, 'offset')
      if (!focusing || focusing?.offset > 256) return
      this.hash = focusing.hash
    },

    scrollTo (hash) {
      const ref = document.querySelector(hash)
      if (!ref) return
      window.scrollTo({
        top: ref.offsetTop,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.help-topic {
  &__layout {
    display: grid;
    word-break: break-word;
    grid-template-areas: 'header' 'sidebar' 'body';
    @include media(md) {
      grid-template-columns: 1fr 240px;
      grid-template-areas:
        'header sidebar'
        'body sidebar';
    }
    > * {
      min-width: 0;
    }
  }
  &__header {
    grid-area: header;
    padding-top: 1.5rem;
  }
  &__body {
    grid-area: body;
    padding-bottom: 1.5rem;
  }
  &__header,
  &__body {
    width: 100%;
    max-width: 680px;
    color: darken($--color-text-secondary, 15);
    font-size: 15px;
    line-height: 170%;
    margin-left: auto;
    margin-right: auto;
    @include media(md) {
      font-size: 16px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &__section {
    &-title {
      font-weight: $--font-weight-bold;
      font-size: 12px;
      color: $--color-text-secondary;
    }
  }
  &__sidebar {
    grid-area: sidebar;
    margin: 2rem 0 1.5rem;
    max-width: 680px;
    @include media(sm-down) {
      width: 100%;
      padding: 2rem 1.5rem 1rem;
      margin: 2rem auto 0;
      border-radius: $--radius-lg;
      background: $--color-page-background;
    }
    @include media(md) {
      position: sticky;
      top: 120px;
      flex: 0 240px;
      align-self: flex-start;
    }
  }
  &__heading {
    display: block;
    margin: .75em 0;
    position: relative;
    transition: color .2s ease-in-out;
    &:before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -1rem;
      border-radius: $--radius;
      background: $--color-primary;
      opacity: 0;
      pointer-events: none;
      transition: opacity .2s ease-in-out;
    }
    &--h1 {
      color: $--color-text-regular;
      font-weight: $--font-weight-bold;
      margin: 1em 0;
      line-height: 150%;
    }
    &--h2 {
      color: $--color-text-secondary;
      padding-left: 1.25rem;
    }
    &--h3,
    &--h4,
    &--h5,
    &--h6 {
      display: none;
    }
    &--active {
      color: $--color-primary;
      &:before {
        opacity: 1;
      }
    }
  }
  &__title {
    font-size: 3rem;
    line-height: 120%;
    color: $--color-text-regular;
    margin: .25em 0;
  }
  &__contact {
    margin: 2rem 0;
    background: $--color-page-background;
    border-radius: $--radius-lg;
    padding: 2rem 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    > * {
      min-width: 0;
      margin: 0;
    }
    h4 {
      font-weight: $--font-weight-normal;
      color: $--color-text-regular;
    }
    a {
      max-width: fit-content;
      display: inline-flex;
      align-items: center;
      gap: .5rem;
      > * {
        min-width: 0;
      }
      i {
        color: $--color-primary;
        font-size: 1.5em;
      }
      span {
        flex: 1;
        white-space: normal;
        line-height: 125%;
        text-align: left;
      }
    }
  }
  &__content {
    ::v-deep {
      .embedded {
        &-anchor {
          color: $--color-text-regular;
          padding-top: 96px;
          margin-top: -96px;
        }
        &-asset {
          text-align: center;
          img {
            max-width: 100%;
            height: auto;
            border-radius: $--radius;
          }
        }
        &-player {
          position: relative;
          width: 100%;
          padding: 0;
          margin: 1.5rem 0;
          height: 0;
          padding-bottom: calc(100% / 16 * 9);
          iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: $--radius-lg;
          }
        }
      }
      h1 {
        font-size: 1.5em;
        line-height: 150%;
        border-top: $--border-light;
        padding-top: 1.5em;
        margin-top: 1em;
      }
      h2 {
        font-size: 1.375em;
        line-height: 150%;
        font-weight: $--font-weight-normal;
        margin: 1em 0;
      }
      h3 {
        font-size: 1.125em;
        line-height: 150%;
        margin: 1em 0;
      }
      h4 {
        font-size: 1.0625em;
        line-height: 150%;
        margin: 1em 0;
      }
      h5, h6 {
        font-size: 1em;
        line-height: 150%;
        margin: 1em 0;
      }
      a {
        color: $--color-primary;
        text-decoration: underline;
      }
      strong, b {
        color: $--color-text-regular;
      }
      p {
        margin: .75em 0;
      }
      li p {
        margin: .5em 0;
      }
      blockquote {
        border-left: 4px solid darken($--color-page-background, 6);
        margin: 1em 0 2em;
        padding-left: 1em;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        td, th {
          border: $--border-light;
          padding: 0 .75em;
        }
        th {
          text-align: left;
        }
      }
    }
  }
}
</style>
